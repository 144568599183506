<template>
  <v-content>
    <v-container grid-list-lg fluid style="margin-bottom: 58px">
      <v-layout wrap align-start justify-start fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title title="Etiquetas">
            <template slot="subtitle">
              Hay
              <span class="font-weight-bold">{{ items.length }}</span>
              etiquetas en total
            </template>
            <template slot="button">
              <div class="btn-all">
                <v-btn
                  v-if="user.shop.admin && user.role.id !== 7"
                  color="back"
                  flat
                  class="mt-0"
                  :to="{ name: 'tagsId', params: { id: 'nuevo' } }"
                >
                  Añadir
                </v-btn>
              </div>
            </template>
          </app-title>
        </v-flex>
        <v-flex xs12 lg12 xl12>
          <v-card class="elevation-2">
            <v-toolbar class="elevation-0">
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchText"
                hide-details
                append-icon="search"
                placeholder="Buscar por nombre"
                flat
                solo
                single-line
                right
                @keyup.native="searchInputHandle"
              ></v-text-field>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="headers"
              :items="items"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              :loading="loading"
              class="elevation-0"
            >
              <template slot="items" slot-scope="props">
                <td width="100">
                  {{ props.item.id }}
                </td>
                <td width="100">
                  {{ props.item.name }}
                </td>
                <td class="text-xs-left">{{ props.item.count }}</td>
                <td class="text-xs-left">{{ props.item.activos }}</td>
                <td class="text-xs-left">{{ props.item.inactivos }}</td>
                <td class="text-xs-left">
                  <v-btn
                    color="info"
                    small
                    depressed
                    :to="{
                      name: 'tagsId',
                      params: { id: props.item.id }
                    }"
                  >
                    Editar
                    <v-icon small right>
                      edit
                    </v-icon>
                  </v-btn>
                  <v-btn color="error" small depressed @click="deleteItem(props.item)">
                    Eliminar
                    <v-icon small right>
                      delete
                    </v-icon>
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn
      v-if="user.shop.admin && user.role.id !== 7"
      fixed
      dark
      fab
      bottom
      right
      color="accent"
      :to="{ name: 'tagsId', params: { id: 'nuevo' } }"
    >
      <v-icon> add </v-icon>
    </v-btn>
  </v-content>
</template>

<script>
import appTitle from '../useful/title.vue'
import { GET_TAGS } from '../../config'

export default {
  name: 'Tags',
  components: { appTitle },
  metaInfo: {
    title: 'Productos'
  },
  data: () => ({
    loading: false,
    menu: false,
    itemsAll: [],
    items: [],
    categories: [],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    productsFilter: {
      on: true,
      off: true,
      hidden: false
    },
    searchText: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nombre',
        align: 'left',
        value: 'name',
        sortable: true
      },
      {
        text: 'Productos asignados',
        align: 'left',
        value: 'count',
        sortable: true
      },
      {
        text: 'Productos activos',
        align: 'left',
        value: 'activos',
        sortable: true
      },
      {
        text: 'Productos inactivos',
        align: 'left',
        value: 'inactivos',
        sortable: true
      },
      {
        text: 'Acciones',
        align: 'left',
        sortable: false
      }
    ],
    dialogStatusStock: false,
    productActive: {},
    stockType: 2,
    stockQuantity: 1
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    async getItems() {
      const res = await this.$http({
        method: 'GET',
        url: GET_TAGS
      })
      this.itemsAll = res.data
      this.items = res.data
    },
    searchInputHandle() {
      const search = this.searchText
      let result = this._.filter(this.itemsAll, item => {
        const res = this._.includes(this._.lowerCase(item.name), this._.lowerCase(search))
        return res
      })
      if (result.length === 0) {
        result = this._.filter(this.itemsAll, item => {
          const res = this._.includes(this._.lowerCase(item.id), this._.lowerCase(search))
          return res
        })
      }
      if (result.length > 0) {
        this.items = result
      } else {
        this.items = []
      }
      this.dialogSearch = false
    },
    async deleteItemAction(item) {
      try {
        const res = await this.$http.delete(`${GET_TAGS}/${item.id}`)
        this.$store.dispatch('setConfirm', { active: false })
        this.$store.dispatch('changeSnack', { active: true, text: res.data.msj })
        this.getItems()
      } catch (error) {
        console.log(error)
      }
    },
    deleteItem(item) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: `¿Esta seguro de eliminar la etiqueta ${item.name}?`,
        description: `Se eliminaran todos los productos(${item.count}) que tengan esta etiqueta`,
        action: () => this.deleteItemAction(item)
      })
    }
  }
}
</script>

<style>
.btn-all .v-badge__badge {
  left: -2px;
}
</style>
